import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import OtherContext from './context/other/otherContext';
import AuthContext from './context/auth/authContext';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import LightTheme from './theme/LightTheme';
import DarkTheme from './theme/DarkTheme';

// Pages
import Dashboard from './pages/Dashboard'
import Layout from './components/Layout/Layout';
import Login from './pages/Login/Login';
import Customers from './pages/Customers'
import Transactions from './pages/Transactions'
// import PpssToll from './pages/PpssToll/PpssToll';
import NotFound from './pages/NotFound';
// import UnderDevelopment from './pages/UnderDevelopment';
import OnboardingToll from './pages/OnboardingToll/OnboardingToll';
// import OnboardingPelindo from './pages/OnboardingPelindo/OnboardingPelindo';
import MonitoringDsrc from './pages/MonitoringDsrc/MonitoringDsrc';
// import MonitoringErp from './pages/MonitoringErp/MonitoringErp';
// import MonitoringQr from './pages/MonitoringQr/MonitoringQr';

export default function Routes({ ...rest }) {
  const otherContext = useContext(OtherContext);
  const authContext = useContext(AuthContext);
  const { token } = authContext;

  return (
    <MuiThemeProvider theme={!otherContext.darkTheme ? LightTheme : DarkTheme}>
      <Switch>
        <Route
          path='/login'
          {...rest}
          render={() => (token ? <Redirect to='/' /> : <Login />)}
        />
        <Route
          path='/'
          render={() => (token ? <DashboardRoute /> : <Redirect to='/login' />)}
        />
      </Switch>
    </MuiThemeProvider>
  );
}

const dataPrivateRoute = [
  {
    path: '/',
    component: Dashboard,
    role: ['admin'],
  },
  {
    path: '/customers-qr',
    component: Customers,
    role: ['admin'],
  },
  {
    path: '/transactions-qr',
    component: Transactions,
    role: ['admin'],
  },
  // {
  //   path: '/branches',
  //   component: PpssToll,
  //   role: ['admin'],
  // },
   {
    path: '/customers-obu',
    component: OnboardingToll,
    role: ['admin'],
  },
  // {
  //   path: '/onboarding-pelindo',
  //   component: OnboardingPelindo,
  //   role: ['admin'],
  // },
  {
    path: '/transactions-obu',
    component: MonitoringDsrc,
    role: ['admin'],
  },
  // {
  //   path: '/monitoring-erp',
  //   component: MonitoringErp,
  //   role: ['admin'],
  // },
  // {
  //   path: '/monitoring-qr',
  //   component: MonitoringQr,
  //   role: ['admin'],
  // },
];

export function DashboardRoute() {
  return (
    <Layout>
      <Switch>
        {dataPrivateRoute.map((row) => (
          <PrivateRoute
            exact
            role={row.role}
            path={row.path}
            component={row.component}
          />
        ))}
        <Route path='/404' component={NotFound} />
        <Route path='*' component={NotFound} />
      </Switch>
    </Layout>
  );
}
