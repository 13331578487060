import { createMuiTheme } from "@material-ui/core/styles";

const DarkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: "rgb(19, 142, 233)",
      contrastText: "#fff"
    }
  },
});

export default DarkTheme;
