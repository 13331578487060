import React from "react";
import moment from "moment";
import Chart from "react-apexcharts";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AuthContext from "../../context/auth/authContext";
import OtherContext from "../../context/other/otherContext";
import Alerts from "../../components/Alerts/Alerts";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

const weekIncome = (weekData, type) => {
  const data = [0, 0, 0, 0, 0, 0, 0];
  weekData.forEach((row) => {
    const dayIndex = moment(row.txn_day).format("e");
    if (type === "earn") {
      data[dayIndex - 1] = row.total_earn;
    }
    if (type === "trx") {
      data[dayIndex - 1] = row.total_trx;
    }
  });
  return data;
};

const sixMonthIncomeTrx = (weekData) => {
  const trx = [0, 0, 0, 0, 0, 0];
  const income = [0, 0, 0, 0, 0, 0];
  const timeValues = [];
  const nameMonth = [];

  var dateStart = moment().subtract(5, "months");
  var dateEnd = moment();
  while (dateEnd > dateStart || dateStart.format("M") === dateEnd.format("M")) {
    timeValues.push(dateStart.format("M"));
    nameMonth.push(dateStart.format("MMM"));
    dateStart.add(1, "month");
  }

  weekData.forEach((row) => {
    const month = moment(row.txn_month).format("M");
    timeValues.forEach((row2, i) => {
      if (month === row2) {
        trx[i] = row.total_trx;
        income[i] = row.total_earn;
      }
    });
  });

  return {
    trx,
    income,
    nameMonth,
  };
};

export default function Dashboard() {
  const authContext = React.useContext(AuthContext);
  const otherContext = React.useContext(OtherContext);
  const { token } = authContext;
  const {
    openAlert,
    typeAlert,
    messageAlert,
    handleOpenAlert,
    handleCloseAlert,
  } = otherContext;
  const classes = useStyles();

  const [state, setState] = React.useState({
    sixMonthTrx: [],
    lastTrx: [],
    weeklyTrxEarn: [],
    yearlyTrxEarn: [],
  });

  const getData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_URL}/api/web/dashboard`,
        {
          headers: {
            Authentication: token,
          },
        }
      );
      const resJson = await res.json();
      setState(resJson.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const barChartData = {
    series: [
      {
        name: "Incomes",
        type: "column",
        data: sixMonthIncomeTrx(state.sixMonthTrx).income,
      },
      {
        name: "Transactions",
        type: "line",
        data: sixMonthIncomeTrx(state.sixMonthTrx).trx,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        toolbar: {
          show: false,
        },
      },
      colors: ["#31B057", "#138ee9"],
      stroke: {
        width: [0, 4],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: sixMonthIncomeTrx(state.sixMonthTrx).nameMonth,
      yaxis: [
        {
          title: {
            text: "Rupiah",
          },
        },
        {
          opposite: true,
        },
      ],
      tooltip: {
        y: {
          formatter: function (val, w) {
            if (w.seriesIndex === 0) {
              return "Rp. " + val.toLocaleString();
            }
            return val;
          },
        },
      },
    },
  };

  const areaChartData = {
    series: [
      {
        data: weekIncome(state.weeklyTrxEarn, "earn"),
      },
    ],
    options: {
      chart: {
        type: "area",
        sparkline: {
          enabled: true,
        },
      },
      colors: ["#31B057"],
      tooltip: {
        fixed: {
          enabled: false,
        },
        y: {
          title: {
            formatter: function (seriesName) {
              return "";
            },
          },
          formatter: function (val) {
            return "Rp. " + val.toLocaleString();
          },
        },
        marker: {
          show: false,
        },
      },
      xaxis: {
        categories: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
      },
    },
  };

  const areaChartData2 = {
    series: [
      {
        data: weekIncome(state.weeklyTrxEarn, "trx"),
      },
    ],
    options: {
      chart: {
        type: "area",
        sparkline: {
          enabled: true,
        },
      },
      colors: ["#138ee9"],
      tooltip: {
        fixed: {
          enabled: false,
        },
        y: {
          title: {
            formatter: function (seriesName) {
              return "";
            },
          },
          formatter: function (val) {
            return val.toLocaleString();
          },
        },
        marker: {
          show: false,
        },
      },
      xaxis: {
        categories: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
      },
    },
  };

  const getTrxIncomeCurrentDay = () => {
    const dataDay = state.weeklyTrxEarn.find(
      (row) =>
        moment(row.txn_day).format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD")
    );
    if (dataDay) {
      return {
        total_trx: dataDay.total_trx,
        total_earn: dataDay.total_earn.toLocaleString(),
      };
    }
    return {
      total_trx: 0,
      total_earn: 0,
    };
  };

  return (
    <>
      {openAlert && (
        <Alerts
          open={openAlert}
          handleOpenAlert={handleOpenAlert}
          handleCloseAlert={handleCloseAlert}
          typeAlert={typeAlert}
          messageAlert={messageAlert}
        />
      )}
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Card
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingLeft: 20,
            }}
          >
            <CardContent>
              <AccountBalanceWalletOutlinedIcon
                style={{
                  fontSize: 50,
                  padding: 10,
                  backgroundColor: "#31B057",
                  color: "#fff",
                  marginBottom: 8,
                  borderRadius: 4,
                }}
              />
              <Typography variant="h4">
                Rp.{" "}
                {state.yearlyTrxEarn.length === 0 ? 0 : Number(state.yearlyTrxEarn[0]?.total_earn).toLocaleString()}
              </Typography>
              <Typography variant="h6" style={{ fontWeight: 400 }}>
                Income This Year
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingLeft: 20,
            }}
          >
            <CardContent style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: 20 }}>
                <AirportShuttleIcon
                  style={{
                    fontSize: 50,
                    padding: 10,
                    backgroundColor: "#138ee9",
                    color: "#fff",
                    marginBottom: 8,
                    borderRadius: 4,
                  }}
                />
                <Typography variant="h4">
                  {state.yearlyTrxEarn.length === 0 ? 0 : state.yearlyTrxEarn[0]?.total_trx}
                </Typography>
                <Typography variant="h6" style={{ fontWeight: 400 }}>
                  Transactions This Year
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card style={{ height: "100%" }}>
                <CardContent
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Typography variant="h6" style={{ fontWeight: 400 }}>
                      Today's Income
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      Rp. {getTrxIncomeCurrentDay().total_earn}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography
                      color="textSecondary"
                      style={{ fontWeight: 400 }}
                    >
                      This Week Income
                    </Typography>
                    <Chart
                      options={areaChartData.options}
                      series={areaChartData.series}
                      type="area"
                      width={"100%"}
                      height={"100px"}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card style={{ height: "100%" }}>
                <CardContent
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Typography variant="h6" style={{ fontWeight: 400 }}>
                      Today's Transactions
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      {getTrxIncomeCurrentDay().total_trx}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography
                      color="textSecondary"
                      style={{ fontWeight: 400 }}
                    >
                      This Week Transactions
                    </Typography>
                    <Chart
                      options={areaChartData2.options}
                      series={areaChartData2.series}
                      type="area"
                      width={"100%"}
                      height={"100px"}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <Card>
            <CardContent>
              <Typography
                variant="h5"
                component="h2"
                style={{ marginBottom: 16 }}
              >
                Last 6 Month Overview
              </Typography>
              <Chart
                options={barChartData.options}
                series={barChartData.series}
                type="line"
                width={"100%"}
                height={320}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={5}>
          <TableContainer component={Paper} style={{ height: "100%" }}>
            <Typography variant="h5" component="h2" style={{ padding: 16 }}>
              Last Transactions
            </Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>createdAt</TableCell>
                  <TableCell align="right">Id Wush</TableCell>
                  <TableCell align="right">Type Trx</TableCell>
                  <TableCell align="right">Tarif</TableCell>
                  <TableCell align="right">Payment</TableCell>
                  <TableCell align="right">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.lastTrx.map((row) => (
                  <TableRow key={row.id_wush}>
                    <TableCell component="th" scope="row">
                      {moment(row.created_at).format("DD-MM-YYYY HH:mm")}
                    </TableCell>
                    <TableCell align="right">{row.id_wush}</TableCell>
                    <TableCell align="right">{row.type_trx}</TableCell>
                    <TableCell align="right">{row.tarif}</TableCell>
                    <TableCell align="right">{row.payment}</TableCell>
                    <TableCell align="right">{row.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
