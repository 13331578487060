import React, { useState, createRef, useContext } from "react";
import MaterialTable from "material-table";
import AuthContext from "../../context/auth/authContext";
import OtherContext from "../../context/other/otherContext";
import Alerts from "../../components/Alerts/Alerts";
import DialogConfirm from "../../components/Dialog/DialogConfirm";
import moment from "moment";

const Customers = () => {
  const authContext = useContext(AuthContext);
  const otherContext = useContext(OtherContext);
  const { token } = authContext;
  const {
    openAlert,
    typeAlert,
    messageAlert,
    handleOpenAlert,
    handleCloseAlert,
  } = otherContext;
  const tableRef = createRef();
  const [dialogConfirmState, setDialogConfirmState] = useState({
    idWush: null,
    open: false,
    title: "",
    subtitle: "",
  });

  const refreshData = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  const handleCloseDialogConfirm = () => {
    setDialogConfirmState({
      idWush: null,
      open: false,
      title: "",
      subtitle: "",
    });
    
  };

  const unbindCustomer = async () => {
    try {
      if (!dialogConfirmState.idWush) {
        handleOpenAlert("error", "id wush null");
        return;
      }
      const res = await fetch(
        `${process.env.REACT_APP_URL}/api/web/customers/unbind/${dialogConfirmState.idWush}`,
        {
          method: "PUT",
          headers: {
            Authentication: token,
          },
        }
      );
      const resJson = await res.json();
      const { status, message } = resJson;
      refreshData()
      if (status === "success") {
        handleCloseDialogConfirm();
        handleOpenAlert(status, message);
      } else {
        handleOpenAlert(status, message);
      }
    } catch (err) {
      handleOpenAlert("error", err.message);
    }
  };

  const dataColumn = [
    { title: "Id Wush", field: "id_wush" },
    { title: "Email", field: "email" },
    { title: "No Hp", field: "no_hp" },
    { title: "Nama", field: "nama" },
    // { title: "Plat Number", field: "plat_no" },
    {
      title: "Payment",
      field: "payment",
      render: (row) => (row.payment === "GOPAY" ? row.payment : ""),
    },
    {
      title: "updatedAt",
      field: "updated_at",
      render: (row) => moment(row.updated_at).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "createdAt",
      field: "created_at",
      render: (row) => moment(row.created_at).format("DD-MM-YYYY HH:mm"),
    },
  ];

  const getData = () => {
    return (query) =>
      new Promise((resolve, reject) => {
        fetch(
          `${process.env.REACT_APP_URL}/api/web/customers?limit=${
            query.pageSize
          }&page=${query.page + 1}&search=${query.search}`,
          {
            headers: {
              Authentication: token,
            },
          }
        )
          .then((res) => res.json())
          .then((result) => {
            if (result.status === "error") {
              handleOpenAlert(result.status, result.message);
              reject();
            }
            resolve({
              data: result.data.rows, // your data array
              page: Number(result.data.page - 1), // current page number
              totalCount: Number(result.data.total), // total value
            });
          })
          .catch((err) => {
            handleOpenAlert("error", err.message);
            reject();
          });
      });
  };

  return (
    <>
      <MaterialTable
        title="Customers"
        tableRef={tableRef}
        columns={dataColumn}
        data={getData()}
        options={{
          actionsColumnIndex: -1,
          paginationType: "normal",
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          sorting: false,
        }}
        actions={[
          {
            icon: "refresh",
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () => refreshData(),
          },
          rowData => ({
            icon: 'delete',
            tooltip: 'Unbind Midtrans/Gopay',
            onClick: (event, row) => setDialogConfirmState({
              ...dialogConfirmState,
              idWush: row.id_wush,
              open: true,
              title: "Unbind Midtrans/Gopay",
              subtitle:
                "Are you sure to unbind Midtrans/Gopay with account id " +
                row.id_wush +
                " ?",
            }),
          })
        ]}
      />
      {openAlert && (
        <Alerts
          open={openAlert}
          handleOpenAlert={handleOpenAlert}
          handleCloseAlert={handleCloseAlert}
          typeAlert={typeAlert}
          messageAlert={messageAlert}
        />
      )}
      <DialogConfirm
        open={dialogConfirmState.open}
        title={dialogConfirmState.title}
        subtitle={dialogConfirmState.subtitle}
        handleClick={() => unbindCustomer()}
        handleClose={handleCloseDialogConfirm}
      />
    </>
  );
};

export default Customers;
