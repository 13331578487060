import React from "react";
import ReactJson from "react-json-view";

const LogTrxLinkAja = ({ data }) => {
  return (
    <div>
      <ReactJson
        src={data}
        iconStyle="triangle"
        style={{ width: "375.56px" }}
        displayDataTypes={false}
        collapseStringsAfterLength={40}
        name={false}
      />
    </div>
  );
};

export default LogTrxLinkAja;
