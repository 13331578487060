import React, { useState, createRef, useContext } from "react";
import MaterialTable from "material-table";
import AuthContext from "../../context/auth/authContext";
import OtherContext from "../../context/other/otherContext";
import Alerts from "../../components/Alerts/Alerts";
import moment from "moment";
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Button, Card, CardContent, Grid, TextField } from '@material-ui/core';

const Transactions = () => {
  const [ dateState, setDateState ] = useState({
    startDate: null,
    endDate: null,
    search: '',
  })
  const authContext = useContext(AuthContext);
  const otherContext = useContext(OtherContext);
  const { token } = authContext;
  const {
    openAlert,
    typeAlert,
    messageAlert,
    handleOpenAlert,
    handleCloseAlert,
  } = otherContext;
  const tableRef = createRef();
  const { startDate, endDate, search } = dateState;

  const dataColumn = [
    { title: "Id Trx", field: "id_trx" },
    { title: "Id Wush", field: "id_wush" },
    { title: "Type Trx", field: "type_trx" },
    { title: "Gerbang", field: "name_gerbang" },
    { title: "Gardu", field: "id_gardu" },
    { title: "Tarif", field: "tarif" },
    { title: "Payment", field: "payment" },
    { title: "Status", field: "status" },
    {
      title: "updatedAt",
      field: "updated_at",
      render: (row) => moment(row.updated_at).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "createdAt",
      field: "created_at",
      render: (row) => moment(row.created_at).format("DD-MM-YYYY HH:mm"),
    },
  ];

  const refreshData = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  const getData = () => {
    return (query) =>
      new Promise((resolve, reject) => {
        fetch(
          `${process.env.REACT_APP_URL}/api/web/transactions?limit=${
            query.pageSize
          }&page=${query.page + 1}&search=${search}&startDate=${startDate || ''}&endDate=${endDate || ''}`,
          {
            headers: {
              Authentication: token,
            },
          }
        )
          .then((res) => res.json())
          .then((result) => {
            if (result.status === "error") {
              handleOpenAlert(result.status, result.message);
              reject();
            }
            resolve({
              data: result.data.rows, // your data array
              page: Number(result.data.page - 1), // current page number
              totalCount: Number(result.data.total), // total value
            });
          })
          .catch((err) => {
            handleOpenAlert("error", err.message);
            reject();
          });
      });
  };

  return (
    <>
    <Card>
      <CardContent style={{ margin: 16 }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container alignItems="flex-end" spacing={3}>
            <Grid item>
              <KeyboardDateTimePicker
                autoOk
                variant="inline"
                label="Start Date"
                format="dd/MM/yyyy HH:mm"
                value={startDate}
                InputAdornmentProps={{ position: 'start' }}
                onChange={(date) => setDateState({ ...dateState, startDate: moment(date).format('YYYY-MM-DD HH:mm:ss') })}
              />
            </Grid>
            <Grid item>
              <KeyboardDateTimePicker
                autoOk
                variant="inline"
                label="End Date"
                format="dd/MM/yyyy HH:mm"
                value={endDate}
                InputAdornmentProps={{ position: 'start' }}
                onChange={(date) => setDateState({ ...dateState, endDate: moment(date).format('YYYY-MM-DD HH:mm:ss') })}
              />
            </Grid>
            <Grid item>
              <TextField 
                id="standard-search" 
                label="Search" 
                type="search"
                onChange={(e) => setDateState({ ...dateState, search: e.target.value })}
              />
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  if (startDate > endDate) {
                    handleOpenAlert("error", "start date cannot be greater than end date");
                    return
                  }
                  refreshData();
                }}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </CardContent>
      <MaterialTable
        title="Transactions"
        tableRef={tableRef}
        columns={dataColumn}
        data={getData()}
        options={{
          actionsColumnIndex: -1,
          paginationType: "normal",
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          sorting: false,
          search: false,
        }}
        actions={[
          {
            icon: "refresh",
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () => refreshData(),
          },
        ]}
      />
    </Card>
    {openAlert && (
        <Alerts
          open={openAlert}
          handleOpenAlert={handleOpenAlert}
          handleCloseAlert={handleCloseAlert}
          typeAlert={typeAlert}
          messageAlert={messageAlert}
        />
      )}
    </>
  );
}

export default Transactions
