import React, { useContext } from 'react';
import clsx from 'clsx';
import { NavLink, Link } from 'react-router-dom';

import AuthContext from '../../../context/auth/authContext';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import GroupIcon from '@material-ui/icons/Group';
import PeopleIcon from '@material-ui/icons/PeopleOutline';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
// import LocationCityIcon from '@material-ui/icons/LocationCity';
// import LaptopIcon from '@material-ui/icons/Laptop';
// import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
// import CropFreeIcon from '@material-ui/icons/CropFree';
// import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  collapse: {
    marginBottom: '8px',
    paddingLeft: '10px',
  },
  listItemIcon: {
    marginRight: 0,
  },
  logo: {
    height: '35px',
  },
  listItemText: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(43, 193, 246, .1)',
      borderLeft: `4px solid #2bc1f6`,
      borderRadius: '4px',
      '& $listItemIcon': {
        color: '#138ee9',
        marginLeft: '-4px',
      },
    },
    '& + &': {
      marginTop: theme.spacing(1),
    },
  },
  nested: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(43, 193, 246, .1)',
      borderLeft: `4px solid #2bc1f6`,
      borderRadius: '4px',
      '& $listItemIcon': {
        color: '#138ee9',
        marginLeft: '-4px',
      },
    },
  },
  activeListItem: {
    borderLeft: `4px solid #2bc1f6`,
    borderRadius: '4px',
    backgroundColor: 'rgb(43, 193, 246, .1)',
    '& $listItemText': {
      color: theme.palette.text.primary,
    },
    '& $listItemIcon': {
      color: '#138ee9',
      marginLeft: '-4px',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
}));

const dataSidebar = [
  {
    title: 'Dashboard',
    url: '/',
    icon: <DashboardIcon />,
  },
  {
    title: 'Customers QR',
    url: '/customers-qr',
    icon: <GroupIcon />,
  },
  {
    title: 'Customers OBU',
    url: '/customers-obu',
    icon: <PeopleIcon />,
  },
  {
    title: 'Transactions QR',
    url: '/transactions-qr',
    icon: <ReceiptIcon />,
  },
  // {
  //   title: 'Branches',
  //   url: '/branches',
  //   icon: <LocationCityIcon />,
  // },
  {
    title: 'Transactions OBU',
    url: '/transactions-obu',
    icon: <ReceiptOutlinedIcon />,
  },
  // {
  //   title: 'Monitoring ERP',
  //   url: '/monitoring-erp',
  //   icon: <DesktopWindowsIcon />,
  // },
  // {
  //   title: 'Monitoring Trx QR',
  //   url: '/monitoring-qr',
  //   icon: <CropFreeIcon />,
  // },
  // {
  //   title: 'Pelindo',
  //   url: '/onboarding-pelindo',
  //   icon: <DirectionsBoatIcon />,
  // },
];

const Sidebar = ({ open, handleDrawerClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const authContext = useContext(AuthContext);
  return (
    <Drawer
      variant='permanent'
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <img src='/logo-png.png' className={classes.logo} alt='logo-dana' />
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <List>
        {dataSidebar.map((row) => (
          <Tooltip title={row.title} placement='right' arrow>
            <ListItem
              key={row.title}
              className={classes.listItem}
              component={NavLink}
              activeClassName={classes.activeListItem}
              exact
              to={row.url}
            >
              <ListItemIcon className={classes.listItemIcon}>
                {row.icon}
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary={row.title}
              />
            </ListItem>
          </Tooltip>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem
          key={'logout'}
          className={classes.listItem}
          onClick={() => authContext.logout()}
          component={Link}
          to='/login'
        >
          <ListItemIcon className={classes.listItemIcon}>
            <PowerSettingsNewIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary={'Logout'}
          />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
