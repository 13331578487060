import React, { useState, useEffect, useContext, createRef } from "react";
import MaterialTable, { MTableBodyRow } from "material-table";
import Image from "material-ui-image";
import queryString from "query-string";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import Alerts from "../../../components/Alerts/Alerts";
import DialogImage from "../../../components/DialogImage/DialogImage";
import AuthContext from "../../../context/auth/authContext";
import OtherContext from "../../../context/other/otherContext";
import FormPayment from "./FormPayment";
import FormAccount from "./FormAccount";
import FormUnbind from "./FormUnbind";
import ConfirmDelete from "./ConfirmDelete";
import ConfirmAddPayment from "./ConfirmAddPayment";
import ReceivedCabang from "./ReceivedCabang";

const useStyles = makeStyles(() => ({
  dataRow: {
    position: "relative",
  },
}));

const OnboardingObu = ({ props }) => {
  const classes = useStyles();
  const tableRef = createRef();
  const otherContext = useContext(OtherContext);
  const authContext = useContext(AuthContext);
  const [state, setState] = useState({
    queryString: queryString.parse(props.location.search),
    action: null,
    selectedImage: null,
    selectedWushInCabang: null,
    typeFormPament: null,
    openFormUnbind: false,
    openFormPayment: false,
    openFormAccount: false,
    openConfirmDelete: false,
    openConfirmAddPayment: false,
    alertTitle: "",
    alertBody: "",
  });

  const [form, setForm] = useState({
    id: "",
    no_id_obu: "",
    nama: "",
    no_hp: "",
    id_obu: "",
    plat_no: "",
    gol: "I",
    status: "ACTIVE",
    image: "",
    payment: "",
    payments: "",
    imageFile: null,
    oldFoto: "",
  });

  const { token } = authContext;
  const {
    openAlert,
    typeAlert,
    messageAlert,
    handleOpenAlert,
    handleCloseAlert,
    paymentColor,
  } = otherContext;

  const dataColumn = [
    {
      title: "Foto",
      render: (row) => {
        return (
          <Image
            src={`${process.env.REACT_APP_URL}/images-onboarding/${row.foto_plat_no}`}
            aspectRatio={1.4}
            style={{
              cursor: "pointer",
            }}
            onClick={() =>
              setState({
                ...state,
                selectedImage: `${process.env.REACT_APP_URL}/images-onboarding/${row.foto_plat_no}`,
              })
            }
          />
        );
      },
    },
    { title: "id", field: "id", hidden: true },
    { title: "no_id_obu", field: "no_id", hidden: true },
    { title: "payments", field: "payments", hidden: true },
    { title: "Nama", field: "nama" },
    { title: "ID OBU", field: "id_obu" },
    { title: "No Ponsel", field: "no_hp" },
    { title: "No Kendaraan", field: "plat_no" },
    { title: "Gol", field: "gol" },
    {
      title: "Payment",
      field: "payment",
      render: (row) => (
        <Typography variant="subtitle1" style={paymentColor(row.payment)}>
          <Box fontWeight="fontWeightBold">
            {!row.payment ? "-" : row.payment}
          </Box>
        </Typography>
      ),
    },
    {
      title: "Status OBU",
      field: "status",
      render: (row) => (
        <Chip label={row.status} style={getColorStatus(row.status)} />
      ),
    },
    {
      title: "updatedAt",
      render: (row) => moment(row.updated_at).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "createdAt",
      render: (row) => moment(row.created_at).format("DD-MM-YYYY HH:mm"),
    },
  ];

  const onChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

  const handleImageChange = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file && file.size <= 1000000) {
      reader.onloadend = () => {
        setForm({ ...form, image: file, imageFile: reader.result });
      };
      reader.readAsDataURL(file);
    } else {
      handleOpenAlert("error", "Foto No Kendaraan max 1 mb");
    }
  };

  const deleteImage = () => {
    setForm({ ...form, imageFile: "", image: "" });
  };

  const resetForm = () => {
    setForm({
      no_id_obu: "",
      nama: "",
      no_hp: "",
      id_obu: "",
      plat_no: "",
      gol: "I",
      status: "ACTIVE",
      image: "",
      payment: "",
      payments: [],
      imageFile: null,
    });
    setState({
      queryString: null,
      action: null,
      openFormPayment: false,
      typeFormPament: null,
      openFormAccount: false,
      openFormUnbind: false,
      openConfirmDelete: false,
      openConfirmAddPayment: false,
      openWushInCabang: false,
    });
  };

  const detailForm = (action, data) => {
    let {
      id,
      no_id,
      nama,
      no_hp,
      id_obu,
      plat_no,
      gol,
      status,
      payment,
      payments,
      foto_plat_no,
    } = data;
    setForm({
      ...form,
      id,
      no_id_obu: no_id,
      nama,
      no_hp,
      id_obu,
      plat_no,
      gol,
      status,
      payment,
      payments,
      image: foto_plat_no,
      oldFoto: foto_plat_no,
    });
    setState({
      ...state,
      action: action,
      openFormAccount: true,
      openFormPayment: false,
    });
  };

  const getData = () => {
    return (query) =>
      new Promise((resolve) => {
        fetch(
          `${process.env.REACT_APP_URL}/api/web/onboarding?limit=${query.pageSize}&page=${
            query.page + 1
          }&search=${query.search}&typeJoin=INNER`,
          {
            headers: {
              Authentication: token,
            },
          }
        )
          .then((res) => res.json())
          .then((result) => {
            if (result.status === "error") {
              handleOpenAlert(result.status, result.message);
              return;
            }
            resolve({
              data: result.data.rows, // your data array
              page: Number(result.data.page - 1), // current page number
              totalCount: Number(result.data.total), // total value
            });
          });
      });
  };

  const openFormAccount = (action, payment) => {
    setState({
      ...state,
      action: action,
      openFormAccount: true,
      openFormPayment: false,
    });
    setForm({ ...form, payment });
  };

  const getColorStatus = (status) => {
    if (status === "ACTIVE")
      return { backgroundColor: "#01FF27", color: "black" };
    else if (status === "NOT-ACTIVE")
      return { backgroundColor: "#FF3400", color: "white" };
  };

  const refreshData = () => {
    tableRef.current && tableRef.current.onQueryChange();
    resetForm();
  };

  const updatePayment = (data) => {
    // if (state.openConfirmAddPayment === true) {
    return fetch(`${process.env.REACT_APP_URL}/api/web/onboarding/payment`, {
      method: "POST",
      headers: {
        Authentication: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "error") {
          handleOpenAlert(result.status, result.message);
          return;
        }
        refreshData();
        handleOpenAlert(result.status, result.message);
      });
    // }
  };

  const deleteAccount = () => {
    return fetch(
      `${process.env.REACT_APP_URL}/api/web/onboarding/account?noId=${form.no_id}`,
      {
        method: "DELETE",
        headers: {
          Authentication: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        refreshData();
        handleOpenAlert(result.status, result.message);
      })
      .catch((err) => handleOpenAlert("error", err.message));
  };

  const getWushInCabang = (idWush) => {
    return fetch(
      `${process.env.REACT_APP_URL}/api/web/onboarding/wush-in-cabang?id=${idWush}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setState({
          ...state,
          selectedWushInCabang: result.data,
        });
      });
  };

  useEffect(() => {
    if (state.queryString) {
      if (/^DANA/.test(state.queryString.state)) {
        setState({ ...state, openConfirmAddPayment: true });
      } else if (state.queryString.state === process.env.REACT_APP_DANA_STATE) {
        setState({
          ...state,
          action: "Add",
          openFormAccount: true,
        });
        setForm({ ...form, payment: "DANA" });
      } else if (state.queryString.status) {
        handleOpenAlert(state.queryString.status, state.queryString.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.queryString]);

  return (
    <div>
      {state.openConfirmAddPayment && (
        <ConfirmAddPayment
          open={state.openConfirmAddPayment}
          updatePayment={updatePayment}
          handleClose={() =>
            setState({ ...state, openConfirmAddPayment: false })
          }
          data={{
            id: state.queryString.state.replace("DANA", ""),
            authCode: state.queryString.auth_code,
            payment: "DANA",
          }}
        />
      )}
      {openAlert && (
        <Alerts
          open={openAlert}
          handleOpenAlert={handleOpenAlert}
          handleCloseAlert={handleCloseAlert}
          typeAlert={typeAlert}
          messageAlert={messageAlert}
        />
      )}
      {state.openConfirmDelete && (
        <ConfirmDelete
          open={state.openConfirmDelete}
          handleClose={() => setState({ ...state, openConfirmDelete: false })}
          deleteAccount={deleteAccount}
          id={form.id}
        />
      )}
      {state.openFormPayment && (
        <FormPayment
          id={form.id}
          token={token}
          handleOpenAlert={handleOpenAlert}
          open={state.openFormPayment}
          payments={form.payments}
          platNo={form.plat_no}
          noHp={form.no_hp}
          handleClose={resetForm}
          typeFormPament={state.typeFormPament}
          openFormAccount={openFormAccount}
          updatePayment={updatePayment}
        />
      )}
      {state.openFormAccount && (
        <FormAccount
          action={state.action}
          token={token}
          open={state.openFormAccount}
          handleClose={resetForm}
          handleOpenAlert={handleOpenAlert}
          form={form}
          onChange={onChange}
          handleImageChange={handleImageChange}
          deleteImage={deleteImage}
          refreshData={refreshData}
        />
      )}
      {state.openFormUnbind && (
        <FormUnbind
          token={token}
          open={state.openFormUnbind}
          handleClose={resetForm}
          idWush={form.id}
          payments={form.payments}
          handleOpenAlert={handleOpenAlert}
          refreshData={refreshData}
        />
      )}
      {state.selectedImage && (
        <DialogImage
          open={state.selectedImage ? true : false}
          handleClose={() => setState({ ...state, selectedImage: null })}
          src={state.selectedImage}
        />
      )}
      {state.selectedWushInCabang && (
        <ReceivedCabang
          open={state.selectedWushInCabang ? true : false}
          handleClose={() => setState({ ...state, selectedWushInCabang: null })}
          data={state.selectedWushInCabang}
        />
      )}
      <MaterialTable
        title="List Has Obu"
        tableRef={tableRef}
        columns={dataColumn}
        data={getData()}
        options={{
          actionsColumnIndex: -1,
          paginationType: "normal",
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          sorting: false,
        }}
        actions={[
          {
            icon: "person_add",
            tooltip: "Add Account",
            isFreeAction: true,
            onClick: () =>
              // setState({
              //   ...state,
              //   openFormPayment: true,
              //   typeFormPament: "payment",
              // }),
              setState({
                ...state,
                openFormAccount: true,
                action: 'Add'
              }),
          },
          {
            icon: "refresh",
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () => refreshData(),
          },
          {
            icon: "fact_check",
            tooltip: "Received Cabang",
            onClick: (event, rowData) => {
              getWushInCabang(rowData.id);
            },
          },
          {
            icon: "library_add",
            tooltip: "Add Payment",
            onClick: (event, rowData) => {
              setState({
                ...state,
                openFormPayment: true,
                typeFormPament: "payments",
              });
              setForm({
                ...form,
                payments: rowData.payments,
                plat_no: rowData.plat_no,
                no_hp: rowData.no_hp,
                id: rowData.id,
              });
            },
          },
          {
            icon: "backspace",
            tooltip: "Unbind Payment",
            onClick: (event, rowData) => {
              setForm({
                ...form,
                id: rowData.id,
                id_obu: rowData.id_obu,
                payments: rowData.payments,
              });
              setState({ ...state, openFormUnbind: true });
            },
          },
          {
            icon: "edit",
            tooltip: "Edit Account",
            onClick: (event, rowData) => {
              detailForm("Edit", rowData);
            },
          },
          {
            icon: "delete",
            tooltip: "Delete Account",
            onClick: (event, rowData) => {
              const filterNull = rowData.payments.filter((row) => row !== null);
              if (filterNull.length !== 0) {
                handleOpenAlert(
                  "error",
                  "Please unbind all payments in this account first"
                );
                return;
              }
              setForm({
                ...form,
                id_obu: rowData.id_obu,
                no_id: rowData.no_id,
                id: rowData.id,
              });
              setState({ ...state, openConfirmDelete: true });
            },
          },
        ]}
        components={{
          Row: (props) => {
            const componentRender = () => {
              const syncTime = moment(props.data.updated_at)
                .add(6, "seconds")
                .format("YYYY-MM-DD HH:mm:ss");
              const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
              if (currentTime > syncTime) return <MTableBodyRow {...props} />;
              else
                return (
                  <TableRow className={classes.dataRow}>
                    <TableCell>
                      <Image
                        src={`${process.env.REACT_APP_URL}/images-onboarding/${props.data.foto_plat_no}`}
                        aspectRatio={1.3}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setState({
                            ...state,
                            selectedImage: `${process.env.REACT_APP_URL}/images-onboarding/${props.data.foto_plat_no}`,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>{props.data.nama}</TableCell>
                    <TableCell align="center">
                      <CircularProgress color="secondary" size={25} />
                    </TableCell>
                    <TableCell>{props.data.no_hp}</TableCell>
                    <TableCell>{props.data.plat_no}</TableCell>
                    <TableCell>{props.data.gol}</TableCell>
                    <TableCell>
                      <CircularProgress color="secondary" size={25} />
                    </TableCell>
                    <TableCell>
                      <CircularProgress color="secondary" size={25} />
                    </TableCell>
                    <TableCell>
                      {moment(props.data.updated_at).format("DD-MM-YYYY HH:mm")}
                    </TableCell>
                    <TableCell>
                      {moment(props.data.created_at).format("DD-MM-YYYY HH:mm")}
                    </TableCell>
                    <TableCell align="center">Wait 5 seconds</TableCell>
                  </TableRow>
                );
            };

            return componentRender();
          },
        }}
      />
    </div>
  );
};

export default OnboardingObu;
