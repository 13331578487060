import React from "react";
import Button from "@material-ui/core/Button";

const LinkajaRedirect = ({ pgpToken, style }) => {
  return (
    <div style={style}>
      <form
        action="https://mfs-dev.linkaja.com/direct-debit/enable"
        method="post"
        encType="application/x-www-form-urlencoded"
      >
        <input name="Message" value={pgpToken} readOnly hidden />
        <Button type="submit" color="primary">
          Bind Linkaja
        </Button>
      </form>
    </div>
  );
};

export default LinkajaRedirect;
