import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import LinkajaRedirect from "./LinkajaRedirect";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

const FormPayment = ({
  open,
  handleClose,
  openFormAccount,
  typeFormPament,
  payments,
  updatePayment,
  handleOpenAlert,
  id,
  token,
  platNo,
  noHp,
}) => {
  const [value, setValue] = React.useState("");
  const [linkaja, setLinkaja] = useState({
    pgpToken: null,
    loading: false,
  });

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  let selectPayment = ["DINAS", "DANA", "LINKAJA", "EZEELINK"];

  let notDisabledPayment = selectPayment.filter(
    (item) => !payments.includes(item)
  );

  const updatePaymentLinkaja = (data) => {
    return fetch(`${process.env.REACT_APP_URL}/api/web/onboarding/payment`, {
      method: "POST",
      headers: {
        Authentication: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result)
        setLinkaja({ ...linkaja, pgpToken: result.pgpToken, loading: false });
        handleOpenAlert(result.status, result.message);
      })
      .catch((err) => {
        setLinkaja({ ...linkaja, loading: false });
        handleOpenAlert("error", err.message);
      });
  };

  return (
    <div style={{ position: "relative" }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Please choose a payment method"}
        </DialogTitle>
        {typeFormPament === "payment" ? (
          <div>
            <DialogContent>
              <FormControl component="fieldset">
                <FormLabel component="legend">Payment</FormLabel>
                <RadioGroup
                  aria-label="payment"
                  name="payment"
                  value={value}
                  onChange={handleChange}
                >
                  {selectPayment.map((row) => (
                    <FormControlLabel
                      value={row}
                      control={<Radio />}
                      label={row}
                      key={row}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              {value === "DANA" ? (
                <Button
                  href={`${
                    process.env.REACT_APP_DANA_URL_OAUTH
                  }/m/portal/oauth?clientId=${
                    process.env.REACT_APP_DANA_CLIENTID
                  }&scopes=DEFAULT_BASIC_PROFILE,AGREEMENT_PAY,QUERY_BALANCE,CASHIER,MINI_DANA&requestId=${uuidv4()}&state=${
                    process.env.REACT_APP_DANA_STATE
                  }&terminalType=APP&redirectUrl=${
                    process.env.REACT_APP_DANA_REDIRECT_URL
                  }`}
                  color="primary"
                  autoFocus
                >
                  Select
                </Button>
              ) : value === "DINAS" ? (
                <Button
                  onClick={() => openFormAccount("Add", "DINAS")}
                  color="primary"
                  autoFocus
                >
                  Select
                </Button>
              ) : value === "LINKAJA" ? (
                <Button
                  onClick={() => openFormAccount("Add", "LINKAJA")}
                  color="primary"
                  autoFocus
                >
                  Select
                </Button>
              ) : value === "EZEELINK" ? (
                <Button
                  onClick={() => openFormAccount("Add", "EZEELINK")}
                  color="primary"
                  autoFocus
                >
                  Select
                </Button>
              ) : (
                <Button
                  onClick={() => alert("Please select your payment")}
                  color="primary"
                  autoFocus
                >
                  Select
                </Button>
              )}
            </DialogActions>
          </div>
        ) : (
          <div>
            <DialogContent>
              <FormControl component="fieldset">
                <FormLabel component="legend">Payment</FormLabel>
                <RadioGroup
                  aria-label="payment"
                  name="payment"
                  value={value}
                  onChange={handleChange}
                >
                  {payments
                    .filter((row) => row !== null)
                    .map((row) => (
                      <FormControlLabel
                        value={row}
                        disabled
                        control={<Radio />}
                        label={row}
                        key={row}
                      />
                    ))}
                  {notDisabledPayment.map((row) => (
                    <FormControlLabel
                      value={row}
                      control={<Radio />}
                      label={row}
                      key={row}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              {notDisabledPayment.length === 0 ? (
                <Button disabled color="primary" autoFocus>
                  Select
                </Button>
              ) : value === "DANA" ? (
                <Button
                  href={`${
                    process.env.REACT_APP_DANA_URL_OAUTH
                  }/m/portal/oauth?clientId=${
                    process.env.REACT_APP_DANA_CLIENTID
                  }&scopes=DEFAULT_BASIC_PROFILE,AGREEMENT_PAY,QUERY_BALANCE,CASHIER,MINI_DANA&requestId=${uuidv4()}&state=DANA${id}&terminalType=APP&redirectUrl=${
                    process.env.REACT_APP_DANA_REDIRECT_URL
                  }`}
                  color="primary"
                  autoFocus
                >
                  Select
                </Button>
              ) : value === "DINAS" ? (
                <Button
                  onClick={() => {
                    updatePayment({
                      id: id,
                      payment: "DINAS",
                      platNo: platNo,
                    });
                  }}
                  color="primary"
                  autoFocus
                >
                  Select
                </Button>
              ) : value === "LINKAJA" ? (
                <Button
                  onClick={() => {
                    setLinkaja({ ...linkaja, loading: true });
                    updatePaymentLinkaja({
                      payment: "LINKAJA",
                      platNo: platNo,
                      id: id,
                    });
                  }}
                  disabled={linkaja.pgpToken ? true : false}
                >
                  {linkaja.loading ? (
                    <CircularProgress size={15} />
                  ) : (
                    "GET LINK"
                  )}
                </Button>
              ) : value === "EZEELINK" ? (
                <Button
                  onClick={() => {
                    updatePayment({
                      id: id,
                      payment: "EZEELINK",
                      platNo: platNo,
                      noHp: noHp,
                    });
                  }}
                  color="primary"
                  autoFocus
                >
                  SELECT
                </Button>
              ) : (
                <Button
                  onClick={() => alert("coming soon")}
                  color="primary"
                  autoFocus
                >
                  Select
                </Button>
              )}
            </DialogActions>
            {linkaja.pgpToken && (
              <LinkajaRedirect
                pgpToken={linkaja.pgpToken}
                style={{ position: "absolute", bottom: "10px", left: "10px" }}
              />
            )}
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default FormPayment;
