import React, { useEffect, useContext } from "react";
import AuthContext from "../../context/auth/authContext";
import { Route, Redirect } from "react-router-dom";
import jwtDecode from "jwt-decode";

const PrivateRoute = ({ role, component: Component, ...rest }) => {
  const authContext = useContext(AuthContext);
  const decoded = jwtDecode(localStorage.token);

  useEffect(() => {
    authContext.loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        return !role.includes(decoded.user.role) ? (
          <Redirect to="/404" />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

export default PrivateRoute;
