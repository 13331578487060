import React, { useEffect, useContext } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import AuthContext from "../../context/auth/authContext";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Alerts(props) {
  const classes = useStyles();
  const { open, handleCloseAlert, typeAlert, messageAlert } = props;
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (messageAlert === "jwt expired") {
      auth.logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageAlert]);

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseAlert} severity={typeAlert}>
          {messageAlert}
        </Alert>
      </Snackbar>
    </div>
  );
}
