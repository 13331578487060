import React, { useState } from "react";
import { saveAs } from "file-saver";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: "100%",
  },
}));

const FileSettlement = (props) => {
  const { open, handleClose, token, handleOpenAlert } = props;
  const classes = useStyles();
  const [form, setForm] = useState({
    shift: "",
    payment: "DANA",
  });

  const onChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

  const getSettlement = async () => {
    const urlSettle = `${process.env.REACT_APP_URL}/api/web/monit-dsrc/dana/settlement?idShift=${form.shift}`;
    const urlCheckSettle = `${process.env.REACT_APP_URL}/api/web/monit-dsrc/dana/check-settlement?idShift=${form.shift}`;
    const header = {
      Authentication: token,
    };
    try {
      const checkSettle = await fetch(urlCheckSettle, { headers: header });
      const res = await checkSettle.json();
      if (res.status === "success") {
        const resSettle = await fetch(urlSettle, {
          headers: { ...header, "Content-Type": "text/csv" },
        });
        const fileSettle = await resSettle.blob();
        saveAs(fileSettle, `${form.payment}-${form.shift}`);
        setForm({ ...form, shift: "" });
      }
      handleOpenAlert(res.status, res.message);
    } catch (err) {
      handleOpenAlert("error", err.message);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Settlement</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <FormControl className={classes.formControl}>
                <InputLabel id="payment">Payment</InputLabel>
                <Select
                  labelId="payment"
                  id="payment"
                  name="payment"
                  value={form.payment}
                  onChange={onChange}
                >
                  <MenuItem value={"DANA"}>DANA</MenuItem>
                  <MenuItem value={"LINKAJA"} disabled>
                    LINKAJA
                  </MenuItem>
                  <MenuItem value={"EZEELINK"} disabled>
                    EZEELINK
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                required
                id="shift"
                name="shift"
                label="Shift"
                value={form.shift}
                onChange={onChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={getSettlement} color="primary">
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FileSettlement;
