import React, { useContext } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import { Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { lightGreen } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import OtherContext from '../../../context/other/otherContext';

import EditProfile from '../../Profile/EditProfile';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  appBar: {
    background: `linear-gradient(left,#2bc1f6,#138ee9)`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: theme.palette.background.default,
  },
  hide: {
    display: 'none',
    color: theme.palette.background.default,
  },
  themeCont: {
    marginRight: '10px',
  },
  title: {
    color: theme.palette.background.default,
    textTransform: 'capitalize',
  },
  color: {
    color: theme.palette.background.default,
  },
  lightGreen: {
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
  },
  fotoProfile: {
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    width: '100px',
    height: '100px',
    fontSize: '50px',
    marginTop: '20px',
  },
  dialogText: {
    marginTop: '-20px',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const Header = ({ handleDrawerOpen, open }) => {
  const classes = useStyles();
  const otherContext = useContext(OtherContext);
  const location = useLocation();
  const { darkTheme, changeTheme } = otherContext;
  const [state, setState] = React.useState(false);

  const handleClose = () => {
    setState(false);
  };

  return (
    <AppBar
      position='fixed'
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <IconButton
          aria-label='open drawer'
          onClick={handleDrawerOpen}
          edge='start'
          className={clsx(classes.menuButton, {
            [classes.hide]: open,
          })}
        >
          <MenuIcon />
        </IconButton>
        <Grid container justify='space-between' alignItems='center'>
          <Typography variant='h6' noWrap className={classes.title}>
            {location.pathname === '/'
              ? 'Dashboard'
              : location.pathname.substring(1)}
          </Typography>
          <div>
            <Grid container justify='center' alignItems='center'>
              <IconButton
                onClick={() => changeTheme()}
                className={classes.themeCont}
              >
                {!darkTheme ? (
                  <WbSunnyIcon fontSize='large' className={classes.color} />
                ) : (
                  <Brightness2Icon fontSize='large' className={classes.color} />
                )}
              </IconButton>
              {/* <IconButton onClick={handleClickOpen}>
                <Avatar alt="Dev Sharp" className={classes.lightGreen}>
                  R
                </Avatar>
              </IconButton> */}
              <Dialog
                open={state}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby='alert-dialog-slide-title'
                aria-describedby='alert-dialog-slide-description'
              >
                <DialogContent>
                  <Grid
                    container
                    direction='column'
                    justify='center'
                    alignItems='center'
                  >
                    <Avatar alt='Dev Sharp' className={classes.fotoProfile}>
                      R
                    </Avatar>
                    <DialogTitle>Rizki Setyawan</DialogTitle>
                    <DialogContentText className={classes.dialogText}>
                      super admin
                    </DialogContentText>
                    <EditProfile />
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color='primary'>
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </div>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
