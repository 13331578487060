import React from "react";
import OnboardingObu from "./components/OnboardingObu";
import OnboardingNoObu from "./components/OnboardingNoObu";

const Onboarding = (props) => (
  <>
    <OnboardingObu props={props} />
    <div style={{ marginTop: "24px" }}></div>
    <OnboardingNoObu />
  </>
);

export default Onboarding;
