import React, { createRef } from "react";
import Image from "material-ui-image";
import moment from "moment";
import MaterialTable from "material-table";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const OpenSystem = (props) => {
  const {
    token,
    handleOpenAlert,
    handleDetailFoto,
    getLog,
    rupiah,
    statusColor,
    paymentColor,
  } = props;
  const tableRef = createRef();
  const dataColumn = [
    {
      title: "Foto",
      render: (row) => {
        const url = `http://202.147.198.116:81/cpi/cilandak/${row.id_shift.substring(
          2,
          8
        )}/${row.id_gardu}/camera1/${row.no_resi}.jpg`;
        return (
          <Image
            onClick={() => handleDetailFoto(url)}
            aspectRatio={1.4}
            style={{
              cursor: "pointer",
            }}
            src={url}
          />
        );
      },
    },
    { title: "No Kendaraan", field: "plat_no" },
    { title: "No Resi", field: "no_resi" },
    { title: "ID OBU", field: "id_obu" },
    { title: "Cabang", field: "name_cabang" },
    { title: "Gerbang", field: "name_gerbang" },
    { title: "ID Gardu", field: "id_gardu" },
    { title: "ID Shift", field: "id_shift" },
    {
      title: "Tarif",
      field: "tarif",
      render: (row) => `Rp. ${rupiah(row.tarif)}`,
    },
    {
      title: "Payment",
      field: "payment",
      render: (row) => (
        <Typography variant="subtitle1" style={paymentColor(row.payment)}>
          <Box fontWeight="fontWeightBold">{row.payment}</Box>
        </Typography>
      ),
    },
    {
      title: "Status",
      render: (row) => {
        return (
          <Tooltip
            title={
              row.status === "S"
                ? "SUCCESS"
                : row.status === "F"
                ? "FAILED"
                : "PENDING"
            }
            placement="top"
            arrow={true}
          >
            <Chip
              label={row.status}
              style={statusColor(row.status)}
              onClick={() => {}}
            />
          </Tooltip>
        );
      },
    },
    {
      title: "updatedAt",
      render: (row) => moment(row.updated_at).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "jamTglTrx",
      render: (row) => moment(row.jam_tgl_trx).format("DD-MM-YYYY HH:mm"),
    },
  ];

  const getData = () => {
    return (query) =>
      new Promise((resolve) => {
        fetch(
          `${process.env.REACT_APP_URL}/api/web/monit-dsrc/open?limit=${
            query.pageSize
          }&page=${query.page + 1}&search=${query.search}`,
          {
            headers: {
              Authentication: token,
            },
          }
        )
          .then((res) => res.json())
          .then((result) => {
            if (result.status === "error") {
              handleOpenAlert(result.status, result.message);
              return;
            }
            resolve({
              data: result.data.rows, // your data array
              page: Number(result.data.page - 1), // current page number
              totalCount: Number(result.data.total), // total value
            });
          });
      });
  };

  return (
    <MaterialTable
      title=""
      tableRef={tableRef}
      columns={dataColumn}
      data={getData()}
      options={{
        actionsColumnIndex: -1,
        pageSize: 10,
        pageSizeOptions: [10, 25, 50],
        sorting: false,
      }}
      actions={[
        {
          icon: "refresh",
          tooltip: "Refresh Data",
          isFreeAction: true,
          onClick: () => tableRef.current && tableRef.current.onQueryChange(),
        },
        {
          icon: "receipt_long",
          tooltip: "Log Transaksi",
          onClick: (event, rowData) => {
            if (rowData.payment === "DANA") {
              getLog(rowData.id_trx, "dana", "log-open");
            } else if (rowData.payment === "LINKAJA") {
              getLog(rowData.id_trx, "linkaja", "log");
            } else if (rowData.payment === "EZEELINK") {
              getLog(rowData.id_trx, "ezeelink", "log");
            } else {
              alert("Tidak ada Log Transaksi");
            }
          },
        },
      ]}
    />
  );
};

export default OpenSystem;
