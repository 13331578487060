import { ALERT_SUCCESS, ALERT_ERROR, CHANGE_THEME } from "../types";

export default (state, action) => {
  switch (action.type) {
    case ALERT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        openAlert: true,
      };

    case ALERT_ERROR:
      return {
        ...state,
        openAlert: false,
        typeAlert: "",
        messageAlert: "",
      };

    case CHANGE_THEME:
      return {
        ...state,
        darkTheme: !state.darkTheme,
      };

    default:
      return state;
  }
};
