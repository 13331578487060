import React, { useState, useContext, createRef } from "react";
import MaterialTable from "material-table";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ConfirmDelete from "./ConfirmDelete";
import Alerts from "../../../components/Alerts/Alerts";
import AuthContext from "../../../context/auth/authContext";
import OtherContext from "../../../context/other/otherContext";
import FormUnbind from "./FormUnbind";

const OnboardingNoObu = (props) => {
  const tableRef = createRef();
  const otherContext = useContext(OtherContext);
  const authContext = useContext(AuthContext);

  const [state, setState] = useState({
    id: null,
    payments: [],
    openConfirmDelete: false,
    openFormUnbind: false,
  });

  const { token } = authContext;
  const {
    openAlert,
    typeAlert,
    messageAlert,
    handleOpenAlert,
    handleCloseAlert,
    paymentColor,
  } = otherContext;

  const dataColumn = [
    { title: "id", field: "id", hidden: true },
    { title: "payments", field: "payments", hidden: true },
    { title: "Nama", field: "nama" },
    { title: "Email", field: "email" },
    { title: "No Ponsel", field: "no_hp" },
    { title: "No Kendaraan", field: "plat_no" },
    {
      title: "Payment",
      field: "payment",
      render: (row) => {
        return (
          <Typography variant="subtitle1" style={paymentColor(row.payment)}>
            <Box fontWeight="fontWeightBold">
              {!row.payment ? "-" : row.payment}
            </Box>
          </Typography>
        );
      },
    },
    {
      title: "updatedAt",
      render: (row) => moment(row.updated_at).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "createdAt",
      render: (row) => moment(row.created_at).format("DD-MM-YYYY HH:mm"),
    },
  ];

  const getData = () => {
    return (query) =>
      new Promise((resolve) => {
        fetch(
          `${process.env.REACT_APP_URL}/api/web/onboarding?limit=${query.pageSize}&page=${
            query.page + 1
          }&search=${query.search}&typeJoin=LEFT`,
          {
            headers: {
              Authentication: token,
            },
          }
        )
          .then((res) => res.json())
          .then((result) => {
            if (result.status === "error") {
              handleOpenAlert(result.status, result.message);
              return;
            }
            resolve({
              data: result.data.rows, // your data array
              page: Number(result.data.page - 1), // current page number
              totalCount: Number(result.data.total), // total value
            });
          });
      });
  };

  const refreshData = () => {
    tableRef.current && tableRef.current.onQueryChange();
    setState({
      id: null,
      payments: [],
      openConfirmDelete: false,
      openFormUnbind: false,
    });
  };

  const deleteAccount = () => {
    return fetch(`${process.env.REACT_APP_URL}/api/web/onboarding/account?id=${state.id}`, {
      method: "DELETE",
      headers: {
        Authentication: token,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        refreshData();
        handleOpenAlert(result.status, result.message);
      })
      .catch((err) => handleOpenAlert("error", err.message));
  };

  return (
    <>
      {openAlert && (
        <Alerts
          open={openAlert}
          handleOpenAlert={handleOpenAlert}
          handleCloseAlert={handleCloseAlert}
          typeAlert={typeAlert}
          messageAlert={messageAlert}
        />
      )}
      {state.openConfirmDelete && (
        <ConfirmDelete
          open={state.openConfirmDelete}
          handleClose={() => setState({ ...state, openConfirmDelete: false })}
          deleteAccount={deleteAccount}
          id={state.id}
        />
      )}
      {state.openFormUnbind && (
        <FormUnbind
          token={token}
          open={state.openFormUnbind}
          handleClose={() => setState({ ...state, openFormUnbind: false })}
          idWush={state.id}
          payments={state.payments}
          handleOpenAlert={handleOpenAlert}
          refreshData={refreshData}
        />
      )}
      <MaterialTable
        title="List Has No Obu"
        tableRef={tableRef}
        columns={dataColumn}
        data={getData()}
        options={{
          actionsColumnIndex: -1,
          paginationType: "normal",
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          sorting: false,
        }}
        actions={[
          {
            icon: "refresh",
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () => refreshData(),
          },
          {
            icon: "backspace",
            tooltip: "Unbind Payment",
            onClick: (event, rowData) =>
              setState({
                ...state,
                id: rowData.id,
                payments: rowData.payments,
                openFormUnbind: true,
              }),
          },
          {
            icon: "delete",
            tooltip: "Delete Account",
            onClick: (event, rowData) => {
              const filterNull = rowData.payments.filter((row) => row !== null);
              if (filterNull.length !== 0) {
                handleOpenAlert(
                  "error",
                  "Please unbind all payments in this account first"
                );
                return;
              }
              setState({
                ...state,
                id: rowData.id,
                openConfirmDelete: true,
              });
            },
          },
        ]}
      />
    </>
  );
};

export default OnboardingNoObu;
