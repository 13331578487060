import { createMuiTheme } from "@material-ui/core/styles";

const LighTheme = createMuiTheme({
  palette: {
    primary: {
      main: "rgb(19, 142, 233)",
      contrastText: "#fff"
    }
  },
});

export default LighTheme;
