import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import OtherState from "./context/other/OtherState";
import AuthState from "./context/auth/AuthState";

import Routes from "./Routes";

const browserHistory = createBrowserHistory();
function App() {
  return (
    <AuthState>
      <OtherState>
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </OtherState>
    </AuthState>
  );
}

export default App;