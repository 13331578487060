import React from "react";
import ReactJson from "react-json-view";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="caption">
            {row.merchant_trans_id ? row.merchant_trans_id : row.request_id}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="caption">{row.updated_at}</Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="caption">{row.created_at}</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Index</TableCell>
                    <TableCell>Response Dana</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.response_dana.map((historyRow, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {index}
                      </TableCell>
                      <TableCell>
                        {typeof historyRow === "string" ? (
                          <Typography variant="caption">
                            {historyRow}
                          </Typography>
                        ) : (
                          <ReactJson
                            src={historyRow}
                            iconStyle="triangle"
                            style={{ width: "375.56px" }}
                            displayDataTypes={false}
                            collapseStringsAfterLength={40}
                            name={false}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const LogTrxDana = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              {data[0].merchant_trans_id ? "MerchantTransId" : "RequestId"}
            </TableCell>
            <TableCell align="right">Updated at</TableCell>
            <TableCell align="right">Created at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row
              key={
                row.merchant_trans_id ? row.merchant_trans_id : row.request_id
              }
              row={row}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LogTrxDana;
