import React, { useState, useContext, useEffect } from "react";

import OtherContext from "../../context/other/otherContext";
import AuthContext from "../../context/auth/authContext";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import LockIcon from "@material-ui/icons/Lock";

import Alerts from "../../components/Alerts/Alerts";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflow: "visible",
  },
  gridForm: {
    background: `linear-gradient(top,#f2f2f2,#d6d6d6)`,
  },
  rootLogo: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  cardLogo: {
    display: "flex",
    background: `linear-gradient(top,#c60000,#b70101)`,
    justifyContent: "center",
    alignItems: "center",
    height: "74px",
    width: "84px",
    marginTop: "-60px",
    borderRadius: "10px",
  },
  cardRoot: {
    padding: theme.spacing(4),
    overflow: "visible",
  },
  progress: {
    color: "white",
  },
  image: {
    background: `linear-gradient(left,#2bc1f6,#138ee9)`,
  },
  paper: {
    height: "100%",
    margin: theme.spacing(0, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  quote: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  quoteText: {
    color: theme.palette.common.white,
    fontWeight: 300,
    textAlign: "center",
  },
  quoteText2: {
    color: theme.palette.common.black,
    fontWeight: 300,
    textAlign: "center",
  },
}));

export default function SignInSide(props) {
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const otherContext = useContext(OtherContext);
  const { token, login, error, clearFailed } = authContext;
  const {
    openAlert,
    typeAlert,
    messageAlert,
    handleOpenAlert,
    handleCloseAlert,
  } = otherContext;

  const [state, setState] = useState({
    username: "",
    password: "",
    loading: false,
  });
  const { username, password, loading } = state;

  useEffect(() => {
    if (error) {
      if (error === "Network Error") {
        handleOpenAlert("error", "Server sedang offline");
        clearFailed();
      }
      if (error.message === "Incorrect username or password") {
        handleOpenAlert("error", "Username atau password anda salah");
      }
      setState({ ...state, loading: false });
    }
    //eslint-disable-next-line
  }, [error, token, props.history]);

  const onChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    login({
      username,
      password,
    });
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <div className={classes.quote}>
          <Typography variant="h3" className={classes.quoteText}>
            Sign in
          </Typography>
          <Typography variant="h2" className={classes.quoteText}>
            <b>Users</b> onboarding
          </Typography>
          <Typography variant="h5" className={classes.quoteText}>
            {"Copyright © "} Delameta Bilano 2020
          </Typography>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        elevation={6}
        className={classes.gridForm}
      >
        <div className={classes.paper}>
          <Card className={classes.cardRoot}>
            <div className={classes.rootLogo}>
              <Card className={classes.cardLogo}>
                <LockIcon style={{ fontSize: 50, color: "white" }} />
              </Card>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <img
                src="/logo-png.png"
                height="70"
                alt="logo-dana"
                style={{ margin: "0 auto" }}
              />
            </div>
            <form className={classes.form} onSubmit={onSubmit}>
              <TextField
                value={username}
                onChange={onChange}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
              />
              <TextField
                value={password}
                onChange={onChange}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {loading ? (
                  <CircularProgress size={24} className={classes.progress} />
                ) : (
                  <div className={classes.content}>LOGIN</div>
                )}
              </Button>
              <Alerts
                open={openAlert}
                handleOpenAlert={handleOpenAlert}
                handleCloseAlert={handleCloseAlert}
                typeAlert={typeAlert}
                messageAlert={messageAlert}
              />
              <Typography
                style={{ marginBottom: "10px" }}
                color="textSecondary"
              >
                please contact admin to have an account
              </Typography>
            </form>
          </Card>
        </div>
      </Grid>
    </Grid>
  );
}
