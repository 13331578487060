import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import LogTrxDsrc from "./LogTrxDsrc/LogTrxDsrc";
import CloseSystem from "./CloseSystem/CloseSystem";
import OpenSystem from "./OpenSystem/OpenSystem";
import Alerts from "../../components/Alerts/Alerts";
import AuthContext from "../../context/auth/authContext";
import OtherContext from "../../context/other/otherContext";
import FileSettlement from "./FileSettlement/FileSettlement";
import DialogImage from "../../components/DialogImage/DialogImage";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  appBar: {},
  paper: {
    border: "1px solid " + theme.palette.divider,
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100%",
  },
  settlement: {
    position: "absolute",
    top: "5px",
    right: "10px",
    zIndex: "1",
  },
}));

const MonitoringDsrc = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [state, setState] = useState({
    fotoSrc: null,
  });
  const [log, setLog] = useState({
    payment: null,
    open: false,
    data: null,
  });
  const [settlement, setSettlement] = useState({
    open: false,
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const otherContext = useContext(OtherContext);
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const {
    openAlert,
    typeAlert,
    messageAlert,
    handleOpenAlert,
    handleCloseAlert,
    paymentColor,
    statusColor,
    rupiah,
  } = otherContext;

  const getLog = (idTrx, pay, system) => {
    fetch(
      `${process.env.REACT_APP_URL}/api/web/monit-dsrc/${pay}/${system}?idTrx=${idTrx}`,
      {
        headers: {
          Authentication: token,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setLog({ ...log, payment: pay, open: true, data: result.data });
      });
  };

  return (
    <div style={{ position: "relative" }}>
      <Button
        className={classes.settlement}
        onClick={() => setSettlement({ ...settlement, open: true })}
      >
        Settlement
      </Button>
      <FileSettlement
        open={settlement.open}
        handleClose={() => setSettlement({ ...settlement, open: false })}
        token={token}
        handleOpenAlert={handleOpenAlert}
      />
      <AppBar position="static" className={classes.appBar} color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Open System" {...a11yProps(0)} />
          <Tab label="Close System" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <OpenSystem
          token={token}
          handleOpenAlert={handleOpenAlert}
          handleDetailFoto={(src) => setState({ ...state, fotoSrc: src })}
          getLog={getLog}
          paymentColor={paymentColor}
          statusColor={statusColor}
          rupiah={rupiah}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CloseSystem
          token={token}
          handleOpenAlert={handleOpenAlert}
          getLog={getLog}
          statusColor={statusColor}
          paymentColor={paymentColor}
          rupiah={rupiah}
        />
      </TabPanel>
      {openAlert && (
        <Alerts
          open={openAlert}
          handleOpenAlert={handleOpenAlert}
          handleCloseAlert={handleCloseAlert}
          typeAlert={typeAlert}
          messageAlert={messageAlert}
        />
      )}
      {log.open && (
        <LogTrxDsrc
          payment={log.payment}
          open={log.open}
          handleClose={() => setLog({ ...log, open: false, data: null })}
          data={log.data}
        />
      )}
      {state.fotoSrc && (
        <DialogImage
          open={state.fotoSrc ? true : false}
          handleClose={() => setState({ ...state, fotoSrc: null })}
          src={state.fotoSrc}
        />
      )}
    </div>
  );
};

export default MonitoringDsrc;
