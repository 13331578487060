import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import LogTrxDana from "./components/LogTrxDana";
import LogTrxLinkaja from "./components/LogTrxLinkAja";
import LogTrxEzeelink from "./components/LogTrxEzeelink";

const LogTrxDsrc = ({ payment, data, open, handleClose }) => {
  const whichLogTrx = (pay, data) => {
    if (pay === "dana") return <LogTrxDana data={data} />;
    if (pay === "linkaja") return <LogTrxLinkaja data={data} />;
    if (pay === "ezeelink") return <LogTrxEzeelink data={data} />;
  };
  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">Log Transaksi</DialogTitle>
      <DialogContent>{whichLogTrx(payment, data)}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogTrxDsrc;
