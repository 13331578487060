import React from "react";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

export default function ReceivedCabang({ open, handleClose, data }) {
  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="max-width-dialog-title">Received Cabang</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ID Cabang</TableCell>
                  <TableCell align="right">Nama Cabang</TableCell>
                  <TableCell align="right">updatedAt</TableCell>
                  <TableCell align="right">createdAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.id_cabang}>
                    <TableCell component="th" scope="row">
                      {row.id_cabang}
                    </TableCell>
                    <TableCell align="right">{row.name_cabang}</TableCell>
                    <TableCell align="right">
                      {moment(row.updated_at).format("YYYY-MM-DD HH:mm:ss")}
                    </TableCell>
                    <TableCell align="right">
                      {moment(row.created_at).format("YYYY-MM-DD HH:mm:ss")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
