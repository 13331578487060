import React, { useState } from "react";
import LinkajaRedirect from "./LinkajaRedirect";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: "100%",
  },
  uploadImage: {
    boxShadow: "0 0 0 0",
    width: "100%",
    height: "100%",
    backgroundColor: "whitesmoke",
  },
}));

const FormAddAccount = (props) => {
  const classes = useStyles();
  const {
    action,
    open,
    handleClose,
    handleOpenAlert,
    token,
    form,
    onChange,
    handleImageChange,
    deleteImage,
    refreshData,
  } = props;
  const {
    id,
    no_id_obu,
    nama,
    no_hp,
    id_obu,
    plat_no,
    status,
    gol,
    payment,
    payments,
    image,
    imageFile,
    oldFoto,
  } = form;

  const [linkAja] = useState({ pgpToken: null });

  const addData = () => {
    let dataNews = new FormData();
    dataNews.append("nama", nama);
    dataNews.append("no_hp", no_hp);
    dataNews.append("id_obu", id_obu);
    dataNews.append("plat_no", plat_no);
    dataNews.append("gol", gol);
    dataNews.append("status", status);
    dataNews.append("image", image);

    let headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Authentication", token);
    return fetch(
      `${process.env.REACT_APP_URL}/api/web/onboarding`,
      {
        method: "POST",
        headers,
        body: dataNews,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "error") {
          handleOpenAlert(result.status, result.message);
        } else {
          refreshData();
          handleOpenAlert(result.status, result.message);
        }
      });
  };

  const updateData = () => {
    let dataNews = new FormData();
    dataNews.append("id", id);
    dataNews.append("no_id_obu", no_id_obu);
    dataNews.append("nama", nama);
    dataNews.append("no_hp", no_hp);
    dataNews.append("id_obu", id_obu);
    dataNews.append("plat_no", plat_no);
    dataNews.append("gol", gol);
    dataNews.append("status", status);
    dataNews.append("image", image);
    dataNews.append("payment", payment);
    dataNews.append("oldFoto", oldFoto);
    let headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Authentication", token);
    return fetch(`${process.env.REACT_APP_URL}/api/web/onboarding/updated`, {
      method: "POST",
      headers,
      body: dataNews,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "error") {
          handleOpenAlert(result.status, result.message);
          return;
        }
        refreshData();
        handleOpenAlert(result.status, result.message);
      });
  };

  const onSubmitEdit = async (e) => {
    e.preventDefault();
    if (!image) {
      handleOpenAlert("error", "Please input foto no kendaraan !");
    } else {
      updateData();
    }
  };

  const onSubmitAdd = async (e) => {
    e.preventDefault();
    if (!image) {
      handleOpenAlert("error", "Please input foto no kendaraan !");
    } else {
      addData();
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick={true}
      >
        <form onSubmit={action === "Add" ? onSubmitAdd : onSubmitEdit}>
          <DialogTitle id="form-dialog-title">{action} Onboarding</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="nama"
                  name="nama"
                  label="Nama"
                  value={nama}
                  onChange={onChange}
                  fullWidth
                  disabled={linkAja.pgpToken ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <TextField
                  required
                  id="no_hp"
                  name="no_hp"
                  label="No Handphone"
                  value={no_hp}
                  onChange={onChange}
                  fullWidth
                  disabled={linkAja.pgpToken ? true : false}
                />
                
              </Grid>
              <Grid item xs={12} sm={6}>
              <TextField
                  required
                  id="id_obu"
                  name="id_obu"
                  label="ID OBU"
                  value={id_obu}
                  onChange={onChange}
                  fullWidth
                  disabled={linkAja.pgpToken ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  className={classes.formControl}
                  disabled={linkAja.pgpToken ? true : false}
                >
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="status"
                    value={status}
                    onChange={onChange}
                  >
                    <MenuItem value={"ACTIVE"}>Active</MenuItem>
                    <MenuItem value={"NOT-ACTIVE"}>Not-active</MenuItem>
                  </Select>
                </FormControl>
                
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  id="plat_no"
                  name="plat_no"
                  label="No Kendaraan"
                  value={plat_no}
                  onChange={onChange}
                  disabled={linkAja.pgpToken ? true : false}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  className={classes.formControl}
                  disabled={linkAja.pgpToken ? true : false}
                >
                  <InputLabel id="gol">Golongan</InputLabel>
                  <Select
                    labelId="gol"
                    id="gol"
                    name="gol"
                    value={gol}
                    onChange={onChange}
                  >
                    <MenuItem value={"I"}>I</MenuItem>
                    <MenuItem value={"II"}>II</MenuItem>
                    <MenuItem value={"III"}>III</MenuItem>
                    <MenuItem value={"IV"}>IV</MenuItem>
                    <MenuItem value={"V"}>V</MenuItem>
                    <MenuItem value={"VI"}>VI</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
              {action !== "Add" &&
                <FormControl
                  className={classes.formControl}
                >
                  <InputLabel id="gol">Payment</InputLabel>
                    <Select
                      labelId="payment"
                      id="payment"
                      name="payment"
                      value={payment}
                      onChange={onChange}
                    >
                      {payments
                        .filter((row) => row !== null)
                        .map((row) => (
                          <MenuItem key={row} value={row}>
                            {row}
                          </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                }
              </Grid>
              {action === "Add" ? (
                imageFile ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{
                      padding: 0,
                      height: 200,
                      backgroundColor: "whitesmoke",
                      borderRadius: 3,
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      <div
                        style={{
                          position: "absolute",
                          right: "0",
                        }}
                      >
                        <IconButton
                          aria-label="delete"
                          color="secondary"
                          onClick={() => deleteImage()}
                        >
                          <CancelIcon />
                        </IconButton>
                      </div>
                      <img
                        style={{ width: "100%", borderRadius: 3 }}
                        src={imageFile}
                        alt="Not Found!"
                      ></img>
                    </div>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{
                      padding: 0,
                      height: 200,
                      backgroundColor: "whitesmoke",
                      borderRadius: 3,
                    }}
                  >
                    <Tooltip title="Upload Foto No Kendaraan" arrow>
                      <Button
                        variant="contained"
                        component="label"
                        className={classes.uploadImage}
                      >
                        <AddPhotoAlternateIcon />
                        <input
                          type="file"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                        />
                      </Button>
                    </Tooltip>
                  </Grid>
                )
              ) : imageFile ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    padding: 0,
                    height: 200,
                    backgroundColor: "whitesmoke",
                    borderRadius: 3,
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        right: "0",
                      }}
                    >
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        onClick={() => deleteImage()}
                      >
                        <CancelIcon />
                      </IconButton>
                    </div>
                    <img
                      style={{ width: "100%", borderRadius: 3 }}
                      src={imageFile}
                      alt="Not Found!"
                    ></img>
                  </div>
                </Grid>
              ) : image ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    padding: 0,
                    height: 200,
                    backgroundColor: "whitesmoke",
                    borderRadius: 3,
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        right: "0",
                      }}
                    >
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        onClick={() => deleteImage()}
                      >
                        <CancelIcon />
                      </IconButton>
                    </div>
                    <img
                      style={{ width: "100%", borderRadius: 3 }}
                      src={`${process.env.REACT_APP_URL}/images-onboarding/${image}`}
                      alt="Not Found!"
                    ></img>
                  </div>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    padding: 0,
                    height: 200,
                    backgroundColor: "whitesmoke",
                    borderRadius: 3,
                  }}
                >
                  <Tooltip title="Upload Foto No Kendaraan" arrow>
                    <Button
                      variant="contained"
                      component="label"
                      className={classes.uploadImage}
                    >
                      <AddPhotoAlternateIcon />
                      <input
                        type="file"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                      />
                    </Button>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={linkAja.pgpToken ? true : false}
            >
              {action}
            </Button>
          </DialogActions>
        </form>
        {linkAja.pgpToken && (
          <LinkajaRedirect
            pgpToken={linkAja.pgpToken}
            style={{
              position: "absolute",
              bottom: "8px",
              left: "15px",
            }}
          />
        )}
      </Dialog>
    </div>
  );
};

export default FormAddAccount;
