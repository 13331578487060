import React, { useReducer } from 'react';
import OtherContext from './otherContext';
import otherReducer from './otherReducer';
import { ALERT_SUCCESS, ALERT_ERROR, CHANGE_THEME } from '../types';

const OthetState = (props) => {
	const initialState = {
		darkTheme: false,
		openAlert: false,
		typeAlert: '',
		messageAlert: '',
	};

	const [state, dispatch] = useReducer(otherReducer, initialState);

	const getAlertMessage = (message) => {
		if (
			message ===
			'duplicate key value violates unique constraint "bind_obu_dana_pkey"'
		) {
			return 'ID OBU sudah digunakan';
		} else if (
			message ===
			'duplicate key value violates unique constraint "bind_obu_dana_no_hp_key"'
		) {
			return 'No Handphone sudah digunakan';
		} else if (
			message ===
			'duplicate key value violates unique constraint "bind_obu_dana_plat_no_key"'
		) {
			return 'No Kendaraan sudah digunakan';
		} else {
			return message;
		}
	};

	const handleOpenAlert = (type, msg) => {
		dispatch({
			type: ALERT_SUCCESS,
			payload: {
				typeAlert: type,
				messageAlert: getAlertMessage(msg),
			},
		});
	};

	const handleCloseAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		dispatch({
			type: ALERT_ERROR,
		});
	};

	const changeTheme = () => {
		dispatch({
			type: CHANGE_THEME,
		});
	};

	const rupiah = (angka) => {
		let reverse = angka.toString().split('').reverse().join(''),
			ribuan = reverse.match(/\d{1,3}/g);
		ribuan = ribuan.join('.').split('').reverse().join('');
		return ribuan;
	};

	const paymentColor = (value) => {
		if (value === 'DANA') return { color: '#2ABFF6' };
		if (value === 'LINKAJA') return { color: '#E8262A' };
		if (value === 'EZEELINK') return { color: '#EC2328' };
	};

	const statusColor = (status) => {
		if (status === 'S') return { backgroundColor: 'green', color: 'white' };
		if (status === 'P') return { backgroundColor: 'red', color: 'white' };
	};

	return (
		<OtherContext.Provider
			value={{
				openAlert: state.openAlert,
				typeAlert: state.typeAlert,
				messageAlert: state.messageAlert,
				darkTheme: state.darkTheme,
				handleOpenAlert,
				handleCloseAlert,
				changeTheme,
				paymentColor,
				statusColor,
				rupiah,
			}}
		>
			{props.children}
		</OtherContext.Provider>
	);
};

export default OthetState;
