import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  formProfile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
      width: 400
    }
  }
}));

export default function EditProfile() {
  const classes = useStyles();
  return (
    <form className={classes.formProfile} noValidate autoComplete="off">
      <TextField label="username" value="admin" size="small" disabled />
      <TextField label="name" value="Rizki Setyawan" size="small" />
      <TextField label="password" value="Small" size="small" type="password" />
      <TextField
        label="retype password"
        value="Small"
        size="small"
        type="password"
      />
    </form>
  );
}
