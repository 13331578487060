import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const FormUnbind = ({
  open,
  handleClose,
  payments,
  idWush,
  token,
  handleOpenAlert,
  refreshData,
}) => {
  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleUnbindDana = () => {
    return fetch(
      `${process.env.REACT_APP_URL}/api/web/onboarding/unbind-payment/${idWush}`,
      {
        method: "DELETE",
        headers: {
          Authentication: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ payment: value }),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "error") {
          handleOpenAlert(result.status, result.message);
          return;
        }
        refreshData();
        handleOpenAlert(result.status, result.message);
      })
      .catch((err) => handleOpenAlert("error", err.message));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Select one to unbind payment"}
      </DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <FormLabel component="legend">Payment</FormLabel>
          <RadioGroup
            aria-label="payment"
            name="payment"
            value={value}
            onChange={handleChange}
          >
            {payments
              .filter((row) => row !== null)
              .map((row) => {
                return (
                  <FormControlLabel
                    value={row}
                    control={<Radio />}
                    label={row}
                    key={row}
                  />
                );
              })}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleUnbindDana}
          color="primary"
          disabled={value.length === 0 ? true : false}
        >
          Unbind
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormUnbind;
